.typorgaphy {
    color: inherit;
    font-size: var(--font-size-body);
}
.typorgaphy.typorgaphy_normal {
    font-weight: normal;
}
.typorgaphy.typorgaphy_bold {
    font-weight: 600;
}
.typorgaphy.typorgaphy_semibold {
    font-weight: 500;
}
.typorgaphy.typorgaphy_extra-bold {
    font-weight: bold;
}
.typorgaphy.typorgaphy_small {
    font-size: var(--font-size-small);
}
.typorgaphy.typorgaphy_body {
    font-size: var(--font-size-body);
}
.typorgaphy.typorgaphy_subtitle {
    font-size: var(--font-size-subtitle);
}
.typorgaphy.typorgaphy_large {
    font-size: var(--font-size-large);
}
.typorgaphy.typorgaphy_h1 {
    font-size: var(--font-size-h1);
}
.typorgaphy.typorgaphy_h2 {
    font-size: var(--font-size-h2);
}
.typorgaphy.typorgaphy_h3 {
    font-size: var(--font-size-h3);
}
.typorgaphy.typorgaphy_h4 {
    font-size: var(--font-size-h4);
}
.typorgaphy.typorgaphy_h5 {
    font-size: var(--font-size-h5);
}
.typorgaphy.typorgaphy_h6 {
    font-size: var(--font-size-h6);
}
.typorgaphy.typorgaphy_promo {
    font-size: 100px;
}

.typorgaphy_color-inherit {
    color: inherit;
}
.typorgaphy_color-primary {
    color: var(--color-primary)
}
.typorgaphy_color-secondary {
    color: var(--color-secondary);
}
.typorgaphy_color-error {
    color: var(--color-error);
}