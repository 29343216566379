/* const cls = [className, 'icon-button', `icon-button_${size}`, `icon-button_${variant}`]; */
.icon-button {
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: var(--radius-circle);
    padding: var(--spacing-base);
    cursor: pointer;
}

.icon-button.icon-button_link {
    background-color: transparent;
    color: var(--color-text);
}
/* variant */
.icon-button.icon-button_primary {
    background-color: var(--color-primary);
}
.icon-button.icon-button_secondary {
    background-color: var(--color-secondary);
}

/* sizes */
.icon-button.icon-button_md {
    width: 25px;
    height: 25px;
    font-size: var(--font-size-body);
}
.icon-button.icon-button_lg {
    width: 35px;
    height: 35px;
    font-size: var(--font-size-large);
}
.icon-button.icon-button_lgx {
    width: 60px;
    height: 60px;
    font-size: var(--font-size-h5);
}