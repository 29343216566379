.textfield {}

.textfield__label {
    position: relative;
    top: calc(var(--spacing-base) * 3);
    transition: all .2s ease-in-out;
}
.textfield__label.textfield__label_active {
    top: 0;
    font-size: var(--font-size-small);
}
.textfield__input {
    border: 0;
    outline: 0;
    border-bottom: 2px solid var(--color-border);
    width: 100%;
    padding-top: var(--spacing-base);
    padding-bottom: var(--spacing-base);
    transition: all .3s ease-out;

}
.textfield__input:focus {
    border-color: var(--color-primary);
}