.menu-list__operations {
    /* margin: calc(var(--spacing-base) * -1); */
}

.menu-list__dir-title-container {
    padding-top: var(--spacing-double);
    display: flex;
    align-items: baseline;
}
.menu-list__dir-title-container .menu-list__dir-title {
    font-size: var(--font-size-h6);
    cursor: pointer;
}
.menu-list__dir-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: var(--font-size-h5);
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: var(--spacing-small);
}

.menu-list__operations {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: var(--spacing-base);
    flex-flow: row wrap;
}
.menu-list__operations .operation {
    flex: 1 1 calc(50% - var(--spacing-base) / 2);
}
