.input-search-container {
    position: relative;
}
.input-search {
    padding: var(--spacing-base) var(--spacing-double);
    border-radius: var(--radius-base);
    outline: 0;
    box-shadow: none;
    border: 0;
    background-color: var(--color-border);
    width: 100%;
}
.input-search:focus {
    border: none;
}
.search-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    left: 21px;
}
.has-search-icon .input-search {
    padding-left: 50px;
}