.prereg-calendar__inner {
    display: flex;
    justify-content: space-around;
    flex-flow: column;
    align-items: center;
}

.prereg-calendar button {
    flex: 1 1 100%;
    width: 100%;
}