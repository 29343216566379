.layout-prereg {
    display: flex;
    flex-flow: column;
    height: 100%;
}
.layout-prereg__header {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-double) var(--spacing-base);
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-layout);
    z-index: 1;
}
.layout-prereg__header-title {
    flex: 1 1 calc(100% - 50px);
    text-align: center;
}
.layout-prereg__header-control {
    flex: 0 0 50px;
}
.layout-prereg__header-empty {
    flex: 0 0 50px;
}
.layout-prereg__content {
    flex: 1 1 100%;
    padding-left: var(--spacing-double);
    padding-right: var(--spacing-double);
    position: relative;
}

.layout-prereg__content-inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all .5s;
}