.input-code {
    padding: var(--spacing-base) var(--spacing-double);
    outline: 0;
    box-shadow: none;
    border: none;
    border-bottom: 3px solid var(--color-border);
    background-color: transparent;
    width: 100%;
    /*font-size: var(--font-size-h3);*/
    font-size: 10vw;
    text-align: center;
    transition: border .5s;
}
.input-code:focus {
    border-bottom: 3px solid var(--color-primary);
}
