/* const cls = [className, 'button', `button_${size}`, `button_${variant}`]; */
.button {
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: var(--radius-base);
    padding: var(--spacing-small) var(--spacing-base);
    cursor: pointer;
    width: 100%;
    outline: 0;
    opacity: 1;
    transition: all .5s;
}

.button:disabled {
    opacity: 0.5;
}

.button:hover {
    opacity: 0.8;
}


.button.button_link {
    background-color: transparent;
    color: var(--color-text);
}
/* variant */
.button.button_primary {
    background-color: var(--color-primary);
    color: var(--color-secondary);
}
.button.button_outlined-active-color {
    border: 1px solid var(--color-active);
    background: transparent;
}
.button.button_secondary {
    background-color: var(--color-secondary);
    color: var(--color-primary);
}

/* sizes */
.button.button_md {
    padding: var(--spacing-base) var(--spacing-small);
    font-size: var(--font-size-body);
}
.button.button_lg {
    padding: var(--spacing-double) var(--spacing-base);
    font-size: var(--font-size-large);
}