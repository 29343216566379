body {
  font-size: var(--font-size-body);
}
.App {
  background-color: var(--color-layout);
}

.overlay.overlay_app {
  /* display: none; */
  background: var( --color-backdrrop);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000500;
}
.overlay.overlay_open {
  transform: translateX(-100%);
  opacity: 1;;
}


.in .slide-enter {
  /* opacity: 0; */
  transform: translateX(0);
}
.in .slide-enter-active {
  /* opacity: 1; */
  display: none;
  transform: translateX(-100%);
  transition: all 300ms ease-out;
}
.in  .slide-exit {
  /* opacity: 1; */
  transform: translateX(-100%);
}
.in .slide-exit-active {
  /* opacity: 0; */
  /* display: none; */
  transform: translateX(0);
  transition: all 300ms ease-out;
}

.out .slide-enter {
  /* opacity: 0; */
  transform: translateX(0);
}
.out .slide-enter-active {
  /* opacity: 1; */
  display: none;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}
.out  .slide-exit {
  /* opacity: 1; */
  transform: translateX(100%);
}
.out .slide-exit-active {
  /* opacity: 0; */
  transform: translateX(0);
  transition: all 300ms ease-out;
}

.none .slide-enter {
   opacity: 0;
  /*transform: translateX(0);*/
}
.none .slide-enter-active {
   opacity: 1;
  /*display: none;*/
  /*transform: translateX(100%);*/
  /*transition: all 500ms ease-in;*/
}
.none  .slide-exit {
   opacity: 1;
  /*transform: translateX(100%);*/
}
.none .slide-exit-active {
   opacity: 0;
  /*display: none;*/
  /*transform: translateX(0);*/
  /*transition: all 500ms ease-out;*/
}


.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}


@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


.fade-anim {
  animation: fade .5s;
}

.slide-in-anime {
  animation: slide-in .3s ease-in-out;
}

.Toastify__toast {
  border-radius: var(--radius-base) !important;
}
/*.Toastify__progress-bar--default {*/
/*  display: none;*/
/*}*/