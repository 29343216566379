.text-center {
    text-align: center;
}
.px {
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
}

.px-1 {
    padding-left: var(--spacing-base);
    padding-right: var(--spacing-base);
}
.px-2 {
    padding-left: var(--spacing-double);
    padding-right: var(--spacing-double);
}
.px-3 {
    padding-left: var(--spacing-triple);
    padding-right: var(--spacing-triple);
}
.px-4 {
    padding-left: var(--spacing-quadro);
    padding-right: var(--spacing-quadro);
}

.py {
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-small);
}
.py-1 {
    padding-top: var(--spacing-base);
    padding-bottom: var(--spacing-base);
}
.py-2 {
    padding-top: var(--spacing-double);
    padding-bottom: var(--spacing-double);
}
.py-3 {
    padding-top: var(--spacing-triple);
    padding-bottom: var(--spacing-triple);
}

.pt {
    padding-top: var(--spacing-small);
}
.pt-1 {
    padding-top: var(--spacing-base);
}
.pt-2 {
    padding-top: var(--spacing-double);
}
.pt-3 {
    padding-top: var(--spacing-triple);
}

.pb {
    padding-bottom: var(--spacing-small);
}
.pb-1 {
    padding-bottom: var(--spacing-base);
}
.pb-2 {
    padding-bottom: var(--spacing-double);
}
.pb-3 {
    padding-bottom: var(--spacing-triple);
}


.flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}