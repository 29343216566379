@import './util.css';


body {
  font-family: 'Gilroy', sans-serif;
  --color-primary: #000;
  --color-secondary: #fff;
  --color-layout: #121212;
  --color-text: #fff;
  --color-active: rgb(71, 166, 255);
  --color-activeBlur: rgba(71, 166, 255, 0.5);
  --color-border: #e7e7e7;
  --color-error: #c90101;

  --spacing-small: 5px;
  --spacing-base: 10px;
  --spacing-double: 20px;
  --spacing-triple: 30px;
  --spacing-quadro: 40px;

  --radius-base: 10px;
  --radius-double: 20px;
  --radius-triple: 30px;
  --radius-circle: 100%;

  --font-size-small: 14px;
  --font-size-body: 16px;
  --font-size-subtitle: 18px;
  --font-size-large: 20px;
  --font-size-h1: 46px;
  --font-size-h2: 42px;
  --font-size-h3: 38px;
  --font-size-h4: 34px;
  --font-size-h5: 30px;
  --font-size-h6: 24px;


  --shadow-base: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.light-theme {
  --color-primary: #000;
  --color-secondary: #fff;
  --color-layout: #fff;
  --color-backdrrop: rgba(0, 0, 0, 0.6);
  --color-text: #000;
  --color-active: rgb(255, 92, 71);
  --color-activeBlur: rgba(255, 92, 71, 0.5);
  --color-border: #e7e7e7;
  --shadow-base: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --rsbs-bg: var(--color-layout);
}

.dark-theme {
  --color-primary: #fff;
  --color-secondary: #000;
  --color-layout: #222;
  --color-backdrrop: rgba(255, 255, 255, 0.2);
  --color-text: #fff;
  --color-active: rgb(255, 92, 71);
  --color-activeBlur: rgba(255, 92, 71, 0.5);
  --color-border: #e7e7e7;
  --shadow-base: 0px 0px 5px rgba(255, 255, 255, 0.2);
  --rsbs-bg: var(--color-layout);
}

[data-rsbs-overlay] {
  --rsbs-bg: var(--color-layout);
}
[data-rsbs-backdrop] {
  --rsbs-backdrop-bg: var(--color-backdrrop);
}
[data-rsbs-content] {
  color: var(--color-text);
}
body {
  margin: 0;
  background-color: var(--color-layout);
}
.App {
  margin: 0;
  height: 100vh;
  color: var(--color-text);
  transition: color .5s, background-color .5s
}
* {
  box-sizing: border-box;
}
#root {
  height: 100vh;
  overflow: auto;
}

.go-back h6 {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  padding: 0.5rem 0;
}