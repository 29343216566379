.flat-list {
    transition: all .3s;
    margin-top: 0;
    position: relative;
}
.flat-list__item {
    cursor: pointer;
}

.flat-list_loading {
    margin-top: 100px;
}
.flat-list .flat-list__loader {
    position: absolute;
    bottom: calc(100% - 30px);
    left: 50%;
    transform: translate(-50%, -50%);
}