.layout {
    display: flex;
    flex-flow: column;
    width: 100vw;
    height: 100vh;
}

.content {
    flex: 1 1 100%;
    padding-left: var(--spacing-base);
    padding-right: var(--spacing-base);
    position: relative;
}

.content__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all .5s;
}