.timepicker {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.timepicker__item {
    flex: 0 0 75px;
    margin: var(--spacing-small);
}

.button.peak {
    color: var(--color-active);
}
